import logo from './logo.svg';
import './App.css';
import "./index.css"
import Logo from "./Components/Assets/Logo.png"
import Hero from "./Components/Assets/Hero Image.png"
import About from "./Components/Assets/About.png"
import Why from "./Components/Assets/Why Us.png"
import Welcome from "./Components/Assets/Welcome.png"

function App() {
  return (
    <div className="App">
      <div className="flex flex-col justify-center bg-white">
      <div className="flex flex-col py-px w-full bg-white max-md:max-w-full">
        <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">
          <div className="flex flex-col justify-center  pb-6 w-full bg-white max-md:max-w-full">
            <div className="flex justify-center items-center px-16 py-6 w-full bg-white max-md:px-5 max-md:max-w-full">
              <img
                loading="lazy"
                src={Logo}
                className="max-w-full aspect-[4] w-[340px]"
              />
            </div>
            <img
    loading="lazy"
    src={Hero}
    className="w-full aspect-w-16 aspect-h-9 max-md:max-w-full"
/>
            <div className="self-center mt-14 text-base text-center text-zinc-600 max-md:mt-10">
              Make Memories to Last a Lifetime
            </div>
            <div className="self-center mt-9 text-3xl leading-10 text-center text-zinc-900 max-md:max-w-full">
              Elevating Hospitality Investments Worldwide
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center px-16  w-full bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col justify-center px-6 py-14 bg-white max-md:px-5 max-md:max-w-full">
            <div className="self-center text-2xl font-medium leading-8 text-center text-zinc-900">
              About Futura Hotel
            </div>
            <div className="mt-10 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow text-center max-md:mt-10 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src={About}
                      className="w-full aspect-[2] max-md:max-w-full"
                    />
                    <div className="self-center mt-6 text-lg text-zinc-900">
                      About Us
                    </div>
                    <div className="mt-6 text-base leading-7 text-zinc-600 max-md:max-w-full">
                      Welcome to Futura Hotels, a pioneering force in the realm
                      of hotel ownership
                     
                      and asset management. Our vision is to emerge as a leading
                      hospitality group in
                  
                      the dynamic Asia Pacific region. With a diverse portfolio,
                      we specialize in the
                    
                      acquisition and development of hotels in strategically
                      chosen locations,
                     
                      coupled with the operation of these establishments under
                      renowned
                     
                      international brands. 
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col text-center max-md:mt-10 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src={Why}
                      className="w-full aspect-[2] max-md:max-w-full"
                    />
                    <div className="self-center mt-6 text-lg text-zinc-900">
                      Why us
                    </div>
                    <div className="mt-6 text-base leading-7 text-zinc-600 max-md:max-w-full">
                      Our distinct approach allow us to harness the best of both
                      worlds: integrating
                      
                      the world-class expertise of these esteemed brands with
                      our deep-rooted local
                    
                      proficiency in acquiring assets using various financial
                      models. Through this
                    
                      synergy, we aim to set new standards in hospitality
                      investment on a global scale
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">
          <div className="flex flex-col px-9 pt-px pb-12 w-full bg-white max-md:px-5 max-md:max-w-full">
            <div className="max-md:mr-2.5 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[56%] max-md:ml-0 max-md:w-full">
                  <img
                    loading="lazy"
                    src={Welcome}
                    className="grow w-full aspect-[1.49] max-md:mt-5 max-md:max-w-full"
                  />
                </div>
                <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl text-zinc-900 max-md:max-w-full">
                      Welcome to Futura Hotels
                    </div>
                    <div className="mt-6 text-2xl leading-10 text-zinc-600 max-md:max-w-full">
                      At Futura Hotels, we are dedicated to enhancing the
                      
                      hospitality landscape worldwide, ensuring unparalleled
                     
                      experiences for our guests and delivering exceptional
                     
                      value to our stakeholders. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-20 py-5 mt-10 w-full text-base leading-7 bg-white text-zinc-600 max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src={Logo}
            className="self-center max-w-full aspect-[4] w-[340px]"
          />
          <div className="flex gap-5 justify-between mt-12 max-md:flex-wrap max-md:mt-10 max-md:mr-1 max-md:max-w-full">
            <div>Copyright © 2024 Futura Hotels  - All Rights Reserved.</div>
            <div className="text-right">Powered by Sceniuz</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default App;
